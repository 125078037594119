import config from "helpers/config";
import React from "react";

const STATIC_PREFIX = config.serverStaticPrefix;

interface IProps
	extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
	src: string;
	alt: string;
}

const ExternalImage: React.FC<IProps> = ({ src, alt, ...rest }) => {
	const isAbsoluteUrl = src.startsWith("https://") || src.startsWith("http://");
	const imageSource = isAbsoluteUrl ? src : `${STATIC_PREFIX}${src}`;

	return <img src={imageSource} alt={alt} {...rest} />
};

export default ExternalImage;
