/* eslint-disable no-unused-expressions,no-void */
import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import {
	Stepper,
	Select,
	TSelectChangeEvent,
	TSelectOption,
	CurrencyIcon,
	KeyValues,
	Billboard,
	QRPanel,
	Button,
} from "@btc-alpha/ui-components"

import { TDepositMethod } from "types/deposit"
import { routes } from "constants/routing"
import { MAX_PRICE_PRECISION, MIN_PRICE_PRECISION } from "utils/constants"
import commonMessages from "messages/common"
import financeMessages from "messages/finance"
import { useMst } from "models/Root"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import useAccount from "hooks/useAccount"
import DepositService from "services/DepositService"
import errorHandler from "utils/errorHandler"

import { ReactComponent as IconCircleQuestion } from "assets/icons/circle-question.svg"

import styles from "./deposit.module.scss"

const DEFAULT_COINS: string[] = ["BTC", "ETH", "USDC", "TON"]

type TProps = {
	currency: string
}

const DepositForm: React.FC<TProps> = ({ currency }) => {
	const { formatMessage, formatNumber } = useIntl()
	const {
		account: { balances, isBalancesLoaded },
	} = useMst()
	const { verification_level } = useAccount()
	const localeNavigate = useLocaleNavigate()

	const [step, setStep] = useState<number>(1)

	const defaultCoins = balances.filter(
		({ code, is_deposit_enabled }) =>
			is_deposit_enabled && DEFAULT_COINS.includes(code.toUpperCase()),
	)

	/* Coin */

	const currencyOptions = useMemo(
		() =>
			[...balances]
				.sort((a, b) => {
					if (!a.available !== !b.available) return b.available - a.available
					return a.code.localeCompare(b.code)
				})
				.map<TSelectOption>(({ code, name, available, precision, is_deposit_enabled }) => ({
					icon: <CurrencyIcon name={code} />,
					label: code,
					description: name,
					amount: formatNumber(+available, {
						useGrouping: true,
						minimumFractionDigits: Math.min(MIN_PRICE_PRECISION, precision || MIN_PRICE_PRECISION),
						maximumFractionDigits: precision || MAX_PRICE_PRECISION,
					}),
					disabled: !is_deposit_enabled,
					value: code.toUpperCase(),
				})),
		[balances.length],
	)

	const handleCurrencyChange: TSelectChangeEvent = value => {
		if (!value) {
			setStep(0)
			return
		}
		currency !== value && localeNavigate(routes.profile.getDepositCurrency(value))
		setStep(1)
	}

	/* Chain */

	const [methods, setMethods] = useState<TDepositMethod[]>([])
	const [methodId, setMethodId] = useState<number>(0)

	const loadMethods = async () => {
		if (!currency) return
		try {
			const result = await DepositService.depositMethodsInit({ currency })
			setMethods(result)
		} catch (error) {
			errorHandler(error, false)
		}
	}
	useEffect(() => {
		if (step < 1) {
			setMethods([])
			setMethodId(0)
		} else if (currency && step === 1) {
			setMethods([])
			setMethodId(0)
			loadMethods()
		}
	}, [currency])

	const methodOptions = useMemo(
		() =>
			methods.map<TSelectOption<number>>(({ id, name, is_deposit_enabled }) => ({
				label: name,
				value: id,
				disabled: !is_deposit_enabled,
			})),
		[methods],
	)

	/* Requisites */

	const method = methods.find(({ id }) => id === methodId)
	const {
		attributes = [],
		min_deposit = 0,
		max_deposit = 0,
		deposit_confirmations_need = 0,
		min_verification_level,
	} = method || {}

	const isTag = attributes?.some(({ name }) => name === "tag")

	useEffect(() => {
		if (!method) return
		setStep(2)
	}, [method])

	/* Render */

	//TODO: render verification level if false
	const isEnoughVerificationLevel = (verification_level ?? 0) >= (min_verification_level ?? 0)

	return (
		<Stepper step={step} className={styles.form}>
			<Stepper.Item
				title={formatMessage(financeMessages.select_coin_to_deposit)}
				className={styles.step}
			>
				<Select
					controlClassName={styles.currencies}
					placeholder={formatMessage(financeMessages.select_coin)}
					placeholderIcon={<IconCircleQuestion />}
					options={currencyOptions}
					search
					value={currency}
					loading={!isBalancesLoaded}
					onChange={handleCurrencyChange}
				/>
				{!!balances?.length && !currency && (
					<div className={styles.defaults}>
						{defaultCoins.map(({ code }) => (
							<Button
								key={`deposit-coin-${code}`}
								caption={code}
								icon={<CurrencyIcon name={code} />}
								size="mini"
								kind="secondary"
								disabled={code === currency}
								onClick={() => handleCurrencyChange(code)}
							/>
						))}
					</div>
				)}
			</Stepper.Item>
			<Stepper.Item title={formatMessage(financeMessages.select_a_chain)} className={styles.step}>
				<Select
					placeholder={formatMessage(financeMessages.chain_type)}
					loading={!methods.length}
					options={methodOptions}
					value={methodId}
					onChange={value => setMethodId(value || 0)}
				/>
			</Stepper.Item>
			<Stepper.Item title={formatMessage(financeMessages.confirm_deposit_details)}>
				{isTag && (
					<Billboard
						text={[
							formatMessage(financeMessages.please_fill_in_deposit_address, {
								method: method?.network?.name,
							}),
							formatMessage(financeMessages.deposits_may_be_delayed),
						]}
						bordered
					/>
				)}
				{attributes?.map(({ name, label, value }) => (
					<QRPanel title={label} code={value} className={styles.qr} />
				))}
				<KeyValues
					className={styles.values}
					items={[
						{
							key: formatMessage(financeMessages.minimum_deposit_amount),
							value: `${formatNumber(+min_deposit, {
								maximumFractionDigits: 8,
								useGrouping: false,
							})} ${currency}`,
							separated: !(+max_deposit > 0),
						},
						{
							key: formatMessage(financeMessages.maximum_deposit_amount),
							value: `${formatNumber(+max_deposit, {
								maximumFractionDigits: 8,
								useGrouping: false,
							})} ${currency}`,
							isVisible: +max_deposit > 0,
							separated: true,
						},
						{
							key: formatMessage(financeMessages.deposit_arrival),
							value: `${deposit_confirmations_need} confirmations`,
						},
						{
							key: formatMessage(financeMessages.withdrawal_unlocked),
							value: `${deposit_confirmations_need} confirmations`,
							separated: true,
						},
					]}
				/>
				<div className={styles.text}>
					<span>{formatMessage(commonMessages.important)}:</span>
					<p>
						Please make sure that only {currency} deposit is made via this address. Otherwise, your
						deposited funds will not be added to your available balance — nor will it be refunded.
					</p>
					<p>For successful deposit, please make sure the wallet address is accurate.</p>
					<p>
						If the current address is used, {deposit_confirmations_need} block confirmations are
						required before your deposited funds can be added to your available balance.
					</p>
					<p>
						Please note that the current asset does not support deposit via the smart contract. If
						used, your deposited funds will not be added to your available balance — nor will it be
						refunded.
					</p>
				</div>
				<Billboard
					className={styles.full}
					text={formatMessage(financeMessages.platform_integrity_and_safety)}
				/>
			</Stepper.Item>
		</Stepper>
	)
}

export default observer(DepositForm)
