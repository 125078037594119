import React from "react"
import { useNavigate } from "react-router-dom"
import { useIntl } from "react-intl"
import { pxs, rems, FlagIcon } from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import { queryVars } from "constants/query"
import commonMessages from "messages/common"
import accountMessages from "messages/account"
import exchangeMessages from "messages/exchange"
import competitionsMessages from "messages/competitions"
import listingMessages from "messages/listing"
import supportMessages from "messages/support"
import financeMessages from "messages/finance"
import historyMessages from "messages/history"
import { useMst } from "models/Root"
import useAccount from "hooks/useAccount"
import { appLocales } from "providers/LanguageProvider/i18n"

import IconNews from "assets/icons/menu/news.svg"
import IconTransfer from "assets/icons/menu/transfer.svg"
import IconClockRewind from "assets/icons/menu/clock-rewind.svg"
import IconSpot from "assets/icons/menu/spot.svg"
import IconFutures from "assets/icons/menu/futures.svg"
import IconDatabase from "assets/icons/menu/database.svg"
import IconCompetition from "assets/icons/menu/competition.svg"
import IconListing from "assets/icons/menu/listing.svg"
import IconVote from "assets/icons/menu/vote.svg"
import IconSupport from "assets/icons/menu/support.svg"
import IconApi from "assets/icons/menu/api.svg"
import IconUserPlus from "assets/icons/menu/user-plus.svg"
import IconPassport from "assets/icons/menu/passport.svg"
import IconSecurity from "assets/icons/menu/security.svg"
import IconUsersArrow from "assets/icons/menu/users-arrow.svg"
import IconSettings from "assets/icons/menu/settings.svg"
import IconLogout from "assets/icons/menu/logout.svg"
import IconTranslate from "assets/icons/menu/translate.svg"

import OpenOrders from "assets/icons/header/OpenOrders"
import OrderHistory from "assets/icons/header/OrderHistory"
import TradingHistory from "assets/icons/header/TradingHistory"

import { TRootItem } from "./types"
import { ITEM_ICON } from "./constants"
import Trade from "./contents/Trade"
import Notifications, { NotificationsIcon } from "./contents/Notifications"
import Account from "./contents/Account"
import Wallet from "./contents/Wallet"

import styles from "./navigation.module.scss"

export const useItems = () => {
	const { formatMessage } = useIntl()
	const { render, global } = useMst()
	const { isAuthenticated, isSubAccount, isEmailConfirmed, character, name, verify, logout } =
		useAccount()
	const navigate = useNavigate()

	const localeChangeHandler = (lang: string) => () => {
		global.setLocale(lang)
		navigate({
			[queryVars.pathname]: window.location.pathname.replace(/^\/\w{2}(\/|$)/, `/${lang}$1`),
			[queryVars.search]: window.location.search,
		})
	}

	const left: TRootItem[] = [
		{
			title: formatMessage(commonMessages.news),
			items: [
				{
					link: routes.news.root,
					title: formatMessage(commonMessages.nav_news_of_exchange_title),
					icon: IconNews,
					description: formatMessage(commonMessages.nav_news_of_exchange_desc),
					hidden: !render.news,
				},
				{
					link: routes.stories.root,
					title: formatMessage(commonMessages.alpha_stories),
					description: formatMessage(commonMessages.nav_alpha_stories_desc),
					icon: IconNews,
					hidden: !render.stories,
				},
			],
		},
		{
			title: formatMessage(commonMessages.trade),
			items: [
				{
					link: routes.trade.root,
					title: formatMessage(commonMessages.spot_terminal),
					description: formatMessage(commonMessages.standard_desc),
					icon: IconSpot,
					items: [<Trade />],
				},
				{
					title: formatMessage(commonMessages.futures),
					description: formatMessage(commonMessages.nav_news_of_exchange_desc),
					label: formatMessage(commonMessages.soon),
					labelType: "secondary",
					disabled: true,
					icon: IconFutures,
				},
			],
		},
		{
			link: routes.p2p.root,
			title: "P2P",
			disabled: !render.p2p,
		},
		{
			link: routes.staking.root,
			title: formatMessage(commonMessages.staking),
			disabled: !render.staking,
		},
		{
			link: routes.aml,
			title: formatMessage(commonMessages.checkCrypto),
			disabled: !render.transactionsMonitoring,
		},
		{
			link: routes.aboutUs.root,
			title: formatMessage(commonMessages.about_us),
		},
		{
			title: formatMessage(commonMessages.more),
			items: [
				{
					link: routes.competitions.root,
					title: formatMessage(commonMessages.competitions),
					description: formatMessage(competitionsMessages.trading_competition),
					disabled: !render.competitions,
					icon: IconCompetition,
				},
				{
					link: routes.marginTradingFaq,
					title: `${formatMessage(accountMessages.margin_trading)} - FAQ`,
					description: formatMessage(exchangeMessages.margin_trade_faq_desc),
					hidden: !render.margin,
				},
				{
					link: routes.listing.root,
					title: formatMessage(commonMessages.listing),
					description: formatMessage(commonMessages.listing_desc),
					disabled: !render.listing,
					icon: IconListing,
				},
				{
					link: routes.socialListing.root,
					title: formatMessage(listingMessages.social_listing_header),
					description: formatMessage(listingMessages.social_listing_desc, { ref: "" }),
					disabled: !render.socialListing,
					icon: IconVote,
				},
				{
					link: routes.support.root,
					title: formatMessage(supportMessages.support_center),
					description: formatMessage(supportMessages.support_center_search_header),
					hidden: !render.supportCenter,
					icon: IconSupport,
				},
			],
		},
	]

	const right: TRootItem[] = [
		// Non Authenticated
		{
			link: routes.login.root,
			title: formatMessage(commonMessages.login),
			hidden: isAuthenticated,
		},
		{
			link: routes.register.root,
			title: formatMessage(commonMessages.register),
			hidden: isAuthenticated,
			primary: true,
		},

		// Authenticated
		{
			link: verify(routes.profile.createDeposit),
			title: formatMessage(commonMessages.deposit),
			hidden: !isAuthenticated,
			primary: true,
		},
		{
			title: formatMessage(commonMessages.wallet),
			hidden: !isAuthenticated,
			separated: true,
			items: [
				<Wallet />,
				{
					separated: true,
					group: formatMessage(accountMessages.accounts),
					link: routes.profile.wallets,
					title: formatMessage(financeMessages.wallet_spot),
					icon: IconDatabase,
				},
				{
					separated: true,
					group: formatMessage(financeMessages.manage_crypto),
					link: verify(routes.profile.createTransfer),
					title: formatMessage(financeMessages.transfer),
					icon: IconTransfer,
					hidden: !render.transfers || isSubAccount,
				},
				{
					link: routes.financeHistory.root,
					title: formatMessage(commonMessages.history),
					icon: IconClockRewind,
				},
			],
		},
		{
			title: formatMessage(commonMessages.orders),
			hidden: !isAuthenticated,
			items: [
				{
					link: routes.history.activeOrders,
					title: formatMessage(historyMessages.active_orders),
					icon: <OpenOrders />,
				},
				{
					link: routes.history.closedOrders,
					title: formatMessage(historyMessages.order_history),
					icon: <OrderHistory />,
				},
				{
					link: routes.history.tradesHistory,
					title: formatMessage(historyMessages.trades),
					icon: <TradingHistory />,
				},
			],
		},
		{
			icon: <img src={character} alt={name || ""} className={styles.character} />,
			hidden: !isAuthenticated,
			items: [
				<Account />,
				{
					title: formatMessage(commonMessages.verification),
					link: routes.verification.root,
					hidden: !isEmailConfirmed || isSubAccount,
					separated: true,
					group: formatMessage(accountMessages.manage_account),
					icon: IconPassport,
				},
				{
					title: formatMessage(commonMessages.referrals),
					link: routes.referrals.root,
					hidden: !render.referrals || isSubAccount,
					icon: IconUserPlus,
				},
				{
					title: formatMessage(commonMessages.sub_accounts),
					link: routes.subAccounts.balances,
					hidden: !render.subAccounts || isSubAccount,
					icon: IconUsersArrow,
				},
				{
					title: formatMessage(commonMessages.security),
					link: routes.security.root,
					icon: IconSecurity,
				},
				{
					title: formatMessage(commonMessages.fee),
					link: routes.tradingFees,
					icon: IconSpot,
				},
				{
					title: formatMessage(commonMessages.api),
					link: routes.api.root,
					icon: IconApi,
				},
				{
					title: formatMessage(commonMessages.settings),
					link: routes.settings.root,
					separated: true,
					icon: IconSettings,
				},
				{
					title: formatMessage(commonMessages.logout),
					icon: IconLogout,
					onClick: logout,
				},
			],
			separated: true,
		},
		{
			icon: <NotificationsIcon />,
			hidden: !isAuthenticated,
			items: [<Notifications />],
			itemsWidth: pxs(rems(350)),
		},

		// Common
		{
			icon: IconTranslate,
			items: appLocales.map(({ name, value, country }) => ({
				title: name,
				icon: <FlagIcon name={country} size={ITEM_ICON} round />,
				disabled: global.locale === value,
				onClick: localeChangeHandler(value),
			})),
			separated: true,
		},
	]

	return { left, right }
}
