/* eslint-disable no-unsafe-optional-chaining */
import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { FormattedRelativeTime, useIntl } from "react-intl"
import cookies from "js-cookie"
import { Button, CodeInput, Counter, Field, messageError } from "@btc-alpha/ui-components"

import { IRegisterErrorData, IRegisterResponse } from "types/register"

import { EAccountType, ERegisterStep } from "pages/Register/types"
import { EMAIL_PINCODE_LENGTH } from "constants/verification"
import commonMessages from "messages/common"
import accountMessages from "messages/account"
import { useRegisterContext } from "pages/Register/context"
import AuthService from "services/AuthService"
import config from "helpers/config"

import RedesignedAuthLayout from "layouts/RedesignedAuthLayout"

import Header from "../components/Header/header"

import styles from "./pinCode.module.scss"

const PinCode: FC = () => {
	const { formatMessage } = useIntl()
	const {
		token,
		setStep,
		type: accountType,
		email,
		setEmail,
		response,
		setResponse,
	} = useRegisterContext()

	const [code, setCode] = useState<string>("")
	const [isError, setError] = useState<string | boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => () => setEmail(), [])

	const handleBack = async () => setStep()

	const handleSubmit = async () => {
		try {
			setLoading(true)
			setError(false)
			// const response = await AuthService.confirm({ code, token })
			const newResponse = await AuthService.confirm(code, response?.slug || "")
			// cookies.set(config.sessionCookieName, response.access_token)
			setResponse(newResponse)
			setStep(
				accountType === EAccountType.JURIDICAL
					? ERegisterStep.JURIDICAL_INFO
					: ERegisterStep.PERSONAL_INFO,
			)
		} catch (error) {
			const data = (error as any)?.data as IRegisterErrorData
			if (data && response) {
				const newResponse = { ...response }
				if (data?.pincode_tries_left?.[0])
					newResponse.pincode_tries_left = +data.pincode_tries_left[0]
				if (data?.pincode_generated_at?.[0])
					newResponse.pincode_generated_at = data.pincode_generated_at[0]
				if (data?.pincode_timeout?.[0]) newResponse.pincode_timeout = data.pincode_timeout[0]
				setResponse(newResponse)
			}
			setError(data?.error_message?.[0] || true)
			messageError(data?.error_message?.[0] || data?.error?.[0])
		} finally {
			setLoading(false)
		}
	}

	const handleResend = async () => {
		const newResponse = await AuthService.resend(response?.slug || "")
		setCode("")
		setError(false)
		setResponse(newResponse)
	}

	return (
		<RedesignedAuthLayout
			title={formatMessage(accountMessages.verify_your_email_address)}
			description={formatMessage(accountMessages.sent_confirmation_code_to_email)}
		>
			<div className={styles.root}>
				<Header
					headline={formatMessage(commonMessages.verification_email)}
					description={formatMessage(commonMessages.verification_email_description)}
					email={email}
				/>

				<Field
					fComponent={CodeInput}
					fLabelIcon="ai-mail_outline_new"
					fLabel={formatMessage(accountMessages.email_verification_code)}
					autoFocus
					numeric
					full
					length={EMAIL_PINCODE_LENGTH}
					fError={isError}
					error={!!isError}
					value={code}
					onChange={setCode}
					fSubline={
						<Counter
							destination={response?.pincode_timeout || 0}
							render={value => (
								<div className={styles.delay}>
									{formatMessage(
										typeof response?.pincode_tries_left === "number" &&
											response.pincode_tries_left > 0
											? commonMessages.pincode_input_with_tries
											: response?.pincode_tries_left === 0
											? commonMessages.pincode_input_with_no_tries
											: commonMessages.pincode_input_get_new_pincode_success,
										{
											tries: response?.pincode_tries_left,
											time_to_wait: (
												<FormattedRelativeTime value={value} updateIntervalInSeconds={1} />
											),
										},
									)}
								</div>
							)}
						>
							<Button
								kind="clear"
								size="mini"
								icon="ai-reload"
								iconSize={16}
								caption={formatMessage(
									response?.pincode_tries_left === 0
										? commonMessages.pincode_input_with_no_tries
										: commonMessages.pincode_input_get_new_pincode,
									{ time_to_wait: "" },
								)}
								loading="auto"
								className={styles.resend}
								onClick={handleResend}
							/>
						</Counter>
					}
				/>

				<div className={styles.buttons}>
					<Button icon="ai-arrow_left" kind="outlined" disabled={loading} onClick={handleBack} />
					<Button
						caption={formatMessage(commonMessages.continue)}
						disabled={code.length !== EMAIL_PINCODE_LENGTH}
						loading={loading}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</RedesignedAuthLayout>
	)
}

export default observer(PinCode)
