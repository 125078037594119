/* eslint-disable no-unused-expressions,jsx-a11y/control-has-associated-label */
import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import { AlphaIcon, Button, Table } from "@btc-alpha/ui-components"

import useAccount from "hooks/useAccount"
import { routes } from "constants/routing"
import { STARS_STR } from "constants/wallet"
import { FORMAT_NUMBER_OPTIONS_USD } from "constants/format"
import commonMessages from "messages/common"
import financeMessages from "messages/finance"
import { useMst } from "models/Root"
import { getPageTitle } from "helpers/global"
import RedesignedLayout from "layouts/RedesignedLayout"
import LoadingSpinner from "components/UI/LoadingSpinner"

import { useColumns, useFilters } from "./wallets.hooks"

import styles from "./wallets.module.scss"

const Wallets = () => {
	const { formatNumber, formatMessage } = useIntl()
	const {
		account: { balances, isBalancesLoaded, totalBalance },
		finance: { isBalancesVisible, setIsBalancesVisible },
	} = useMst()
	const columns = useColumns()
	const filters = useFilters()
	const { verify } = useAccount()

	const handleVisibilityClick = () => setIsBalancesVisible(!isBalancesVisible)

	const ordered = useMemo(
		() =>
			[...balances].sort((a, b) => {
				if (!+a.balance !== !+b.balance) return Math.abs(+b.balance) - Math.abs(+a.balance)
				return a.code.localeCompare(b.code)
			}),
		[balances],
	)

	return (
		<RedesignedLayout
			helmet={{ title: getPageTitle(formatMessage(commonMessages.wallet)) }}
			className={styles.wallets}>
			<div className={styles.heading}>
				{/*TODO: translate*/}
				<h2>Main spot</h2>
				<button
					type="button"
					title={formatMessage(
						isBalancesVisible ? financeMessages.hide_balance : financeMessages.show_balance,
					)}
					onClick={handleVisibilityClick}
				>
					<AlphaIcon
						name={isBalancesVisible ? "ai-eye_disabled" : "ai-eye"}
						size={20}
						color="--alpha-ui-color-text-primary"
					/>
				</button>
			</div>
			{!isBalancesLoaded ? (
				<LoadingSpinner />
			) : (
				<>
					<div className={styles.info}>
						<div>
							<span className={styles.title}>{formatMessage(financeMessages.total_equity)}</span>
							<span className={styles.amount}>
								$
								{isBalancesVisible
									? formatNumber(totalBalance.USDT || 0, FORMAT_NUMBER_OPTIONS_USD)
									: STARS_STR}
							</span>
						</div>
						<div>
							<Button
								caption={formatMessage(commonMessages.deposit)}
								icon="ai-credit-card-upload"
								size="mini"
								to={verify(routes.profile.createDeposit)}
							/>
							<Button
								caption={formatMessage(commonMessages.withdraw)}
								icon="ai-credit-card-download"
								size="mini"
								kind="outlined"
								to={verify(routes.profile.createWithdraw)}
							/>
						</div>
					</div>
					<h2>{formatMessage(financeMessages.assets)}</h2>
					<Table
						id="code"
						columns={columns}
						filters={filters}
						data={ordered}
						footer="pagination"
						defLimit={100}
						firstSticky
						lastSticky
					/>
				</>
			)}
		</RedesignedLayout>
	)
}

export default observer(Wallets)
