import React, { useState, useRef } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import * as yup from "yup"
import { Button, CountrySelect, Field, Input, useForm } from "@btc-alpha/ui-components"

import commonMessages from "messages/common"
import accountMessages from "messages/account"
import formMessages from "messages/form"
import useAccount from "hooks/useAccount"
import AuthService from "services/AuthService"
import { useRegisterContext } from "pages/Register/context"
import { ERegisterStep } from "pages/Register/types"

import RedesignedAuthLayout from "layouts/RedesignedAuthLayout"

import Header from "../components/Header"

import styles from "./regPersonalForm.module.scss"

enum EFields {
	NAME = "name",
	SURNAME = "second_name",
	COUNTRY = "issuing_country",
}

type TForm = {
	[EFields.NAME]: string
	[EFields.SURNAME]: string
	[EFields.COUNTRY]: string
}

const RegPersonalForm: React.FC = () => {
	const { formatMessage } = useIntl()
	const { setStep, response } = useRegisterContext()
	const { load } = useAccount()
	const formRef = useRef<HTMLFormElement>(null);

	const { submit, change, submitting, errors } = useForm<TForm>({
		validation: {
			[EFields.NAME]: yup.string().required(formatMessage(formMessages.required)),
			[EFields.SURNAME]: yup.string().required(formatMessage(formMessages.required)),
			[EFields.COUNTRY]: yup.string().required(formatMessage(formMessages.required)),
		},
	})

	const [isFilled, setFilled] = useState<boolean>(false)
	const handleChange = change(data => {
		setFilled(Object.values(data).every(value => !!value))
	})

	const handleSubmit = submit(async data => {
		// await AuthService.sendPersonalInfo(data)
		await AuthService.sendPersonalInfo(
			{
				first_name: data[EFields.NAME],
				last_name: data[EFields.SURNAME],
				citizenship: data[EFields.COUNTRY],
			},
			response?.slug || "",
		)
		// await load()
		setStep(ERegisterStep.FINISH)
	})

	const handleCountryChange = (value: null | string): void => {
		if (value && formRef.current) {
			const syntheticEvent = {
				target: {
					name: EFields.COUNTRY,
					value,
				},
				currentTarget: formRef.current,
			} as unknown as React.FormEvent<HTMLFormElement>

			handleChange(syntheticEvent);
		}
	}

	return (
		<RedesignedAuthLayout
			title={formatMessage(accountMessages.almost_there)}
			description={formatMessage(accountMessages.one_last_step_exchange)}
		>
			<div className={styles.root}>
				<Header
					headline={formatMessage(commonMessages.almost_done)}
					description={formatMessage(commonMessages.almost_done_description)}
				/>

				<form ref={formRef} onSubmit={handleSubmit} onChange={handleChange}>
					<div className={styles.cols}>
						<Field
							fComponent={Input}
							fLabel={formatMessage(commonMessages.first_name)}
							disabled={submitting}
							name={EFields.NAME}
							placeholder={formatMessage(commonMessages.first_name)}
							autoFocus
							fError={errors?.name}
							error={!!errors?.name}
						/>

						<Field
							fComponent={Input}
							fLabel={formatMessage(commonMessages.last_name)}
							disabled={submitting}
							name={EFields.SURNAME}
							placeholder={formatMessage(commonMessages.last_name)}
							fError={errors?.second_name}
							error={!!errors?.second_name}
						/>
					</div>

					<Field
						fComponent={CountrySelect}
						fLabel={formatMessage(commonMessages.country_residence)}
						disabled={submitting}
						name={EFields.COUNTRY}
						fError={errors?.issuing_country}
						error={!!errors?.issuing_country}
						onChange={handleCountryChange}
					/>

					<Button
						type="submit"
						caption={formatMessage(commonMessages.finish)}
						loading={submitting}
						disabled={!isFilled}
						className={styles.submit}
					/>
				</form>
			</div>
		</RedesignedAuthLayout>
	)
}

export default observer(RegPersonalForm)
